<template>
  <div data-app>
    <!-- Content -->
    <v-card>
      <v-row justify="center" align="center" class="mx-0 flex-column">
        <help :helpId="31" class="help--crud" />
        <v-col cols="12 px-10">
          <v-card-title class="pa-0 justify-center">Seleccione un cargo:</v-card-title>
          <multiselect
            class="mx-auto"
            style="max-width: 250px"
            v-model="position"
            label="name"
            track-by="id"
            placeholder="Buscar"
            open-direction="bottom"
            :options="positions"
            :multiple="false"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :clear-on-select="true"
            :close-on-select="true"
            :options-limit="300"
            :limit="3"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            @search-change="getPositions"
            @input="getPositionMetrics()"
          />
        </v-col>
      </v-row>
      <div class="filters">
        <h2 class="text-center" v-if="position && position.name">
          Métricas asignadas a {{ position && position.name ? position.name : '' }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >Asignar
              </v-btn>
            </template>
            <span>Asignar Métrica</span>
          </v-tooltip>
        </h2>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            small
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getPositionMetrics()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getPositionMetrics()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :noDataText="
          !position || !position.name
            ? 'Seleccione un cargo para administrar sus métricas'
            : 'Aún no hay métricas asignadas'
        "
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @showPositionProfile="assignUsersMetrics($event)"
      />
    </v-card>
    <v-dialog
      v-model="metricPositionUserModal"
      style="height: 400px"
      :height="'400px'"
      :width="'80%'"
      :adaptive="true"
    >
      <v-card v-if="item.metric && item.metric.description">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title
            >Metrica: {{ item.metric.description }} . Desde
            {{ toLocaleDateString(item.start_at) }} hasta
            {{ toLocaleDateString(item.end_at) }}</v-toolbar-title
          >
        </v-toolbar>
        <v-tabs horizontal v-model="tab" class="pa-4" justity="center">
          <v-tab>
            <v-icon left> mdi-account-group </v-icon>
            Asignación individual
          </v-tab>
          <v-tab>
            <v-icon left> mdi-account </v-icon>
            Asignación multiple
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-form ref="generalAssignationForm" v-model="valid2" style="width: 100%"
                ><v-row class="mx-0">
                  <v-col
                    cols="12"
                    lg="7"
                    md="6"
                    sm="12"
                    style="padding-left: 0px; margin-top: 10px"
                    ><v-row class="mx-0" style="align-items: center">
                      <label style="width: 20%">Usuario:</label>
                      <multiselect
                        style="width: 80%"
                        v-model="selectedUsers"
                        label="name"
                        track-by="id"
                        placeholder="Seleccionar"
                        open-direction="bottom"
                        :options="users"
                        :multiple="false"
                        :custom-label="fullName"
                        :searchable="true"
                        :loading="isLoading"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="3"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="getUsers"
                      /> </v-row
                  ></v-col>

                  <v-col
                    cols="12"
                    lg="5"
                    md="6"
                    sm="12"
                    style="padding-left: 0px; margin-top: 10px"
                  >
                    <v-text-field
                      dense
                      :rules="[rules.required]"
                      v-model="usersMetric.value"
                      label="Valor"
                      type="number"
                    />
                  </v-col>

                  <v-col cols="12" lg="4" md="6" sm="12">
                    <v-btn
                      small
                      :disabled="!valid2"
                      color="success"
                      class="mr-4"
                      @click="userAssignation()"
                    >
                      Asignar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <v-form ref="metricUsersForm" v-model="valid3" style="width: 100%">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(item, index) in metricPositionUsers"
                    :key="index"
                  >
                    <v-card class="pa-2" outlined color="transparent">
                      <v-list>
                        <v-list-item class="px-0">
                          <v-divider vertical class="mr-2" />
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              v-html="
                                `<strong>${item.user.name} ${item.user.lastname}</strong>`
                              "
                            />
                            <v-container grid-list-md text-xs-center class="px-0">
                              <v-layout row wrap>
                                <v-flex xs2>
                                  <v-list-item-avatar>
                                    <img
                                      v-if="item.user && item.user.avatar"
                                      :src="item.user.avatar.route"
                                    />
                                  </v-list-item-avatar>
                                </v-flex>
                                <v-flex xs4>
                                  <v-text-field
                                    v-model="item.value"
                                    :rules="[rules.required]"
                                    class="mt-3"
                                    label="Valor"
                                  />
                                </v-flex>
                                <v-flex xs6>
                                  <v-text-field
                                    counter="100"
                                    v-model="item.description"
                                    :rules="[rules.max]"
                                    class="mt-3"
                                    label="Observación"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  ><v-btn
                    small
                    style="margin: auto; margin-top: 10px"
                    :disabled="!valid3"
                    dark
                    color="primary"
                    @click="massiveAssignment()"
                  >
                    Asignar
                  </v-btn>
                  <v-btn
                    small
                    style="margin: auto; margin-top: 10px"
                    color="primary"
                    dark
                    @click="$refs.metricUsersForm.reset()"
                  >
                    Limpiar
                  </v-btn>
                  <v-btn
                    small
                    style="margin: auto; margin-top: 10px"
                    color="primary"
                    dark
                    @click="metricPositionUserModal = false"
                  >
                    Cancelar
                  </v-btn></v-row
                >
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="metricPositionModal"
      :height="'80%'"
      :width="'80%'"
      :adaptive="true"
    >
      <v-row class="mx-0">
        <v-col cols="12">
          <v-card style="padding: 20px">
            <h2
              class="text-center mb-2"
              style="justify-content: center; display: flex"
              v-if="flow === 'create'"
            >
              Asignar Métrica a {{ position.name }}
              <!-- <help :helpId="" style="'margin: auto 10px;'" /> -->
            </h2>
            <h2 class="text-center mb-2" v-if="flow === 'edit'">
              Editar Métrica a {{ position.name }}
            </h2>

            <span v-if="flow === 'edit' && item.created_at"
              >Fecha de asignación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
              {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
            >
            <v-form ref="formCompany" v-model="valid" style="width: 100%">
              <v-row class="mx-0" v-if="flow === 'create'">
                <v-col cols="12" md="6">
                  <v-row class="mx-0">
                    <label for="" style="width: 25%">Métrica:</label>
                    <multiselect
                      style="width: 75%"
                      track-by="id"
                      label="description"
                      deselect-label="Remover selección"
                      select-label="Seleccionar"
                      placeholder="Seleccionar"
                      v-model="metric"
                      :options="metrics"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="false"
                    />
                  </v-row>
                  <span
                    v-if="metric && metric.periodicity !== null"
                    class="caption text--secondary"
                  >
                    Esta métrica cambia
                    {{ metric.periodicity | toPeriodicityText }}</span
                  >
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="metricRange"
                        label="Rango de tiempo global"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :hint="
                          metric && metric.periodicity !== null
                            ? `Para asignaciones multiples, seleccione un intérvalo superior o igual a ${intervalText}`
                            : ''
                        "
                        :persistent-hint="true"
                      />
                    </template>
                    <v-date-picker
                      range
                      v-model="metricRange"
                      clearable
                      :selected-items-text="metricRange | toRangeText"
                      min="2000-01-01"
                      @change="handlePeriodicity()"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-row justify="center">
                    <v-expansion-panels accordion multiple focusable v-model="expPanel">
                      <v-expansion-panel v-for="tab in dataMetrics" :key="tab.id">
                        <v-expansion-panel-header
                          ><h4>{{ tab.title }}</h4>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                dark
                                small
                                fab
                                icon
                                elevation="3"
                                absolute
                                style="right: 5%"
                                @click.prevent="deleteDataMetric(tab)"
                                ><v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar este intérvalo de asignación</span>
                          </v-tooltip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mt-4">
                          <v-row class="mx-0">
                            <v-col cols="12" lg="4" md="6" sm="12">
                              <v-menu
                                :ref="`menu${tab.id}`"
                                :key="tab.id"
                                v-model="tab.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="tab.range"
                                    label="Rango efectivo de asignación"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :hint="''"
                                    :persistent-hint="true"
                                  />
                                </template>
                                <v-date-picker
                                  range
                                  :rules="[rules.requiredRange]"
                                  v-model="tab.range"
                                  :selected-items-text="tab.range | toRangeText"
                                  min="2000-01-01"
                                  hint="La meta establecida se evaluará en este rango de tiempo"
                                  persistent-hint
                                />
                              </v-menu>
                            </v-col>
                            <v-col cols="12" lg="4" md="6" sm="12">
                              <v-text-field
                                :rules="[rules.required]"
                                label="Meta establecida"
                                v-model="tab.minimun"
                                hint="Ingrese el valor númérico de la meta establecida"
                                persistent-hint
                                type="number"
                              />
                            </v-col>
                            <v-col cols="12" lg="4" md="6" sm="12">
                              <v-text-field
                                :rules="[rules.required]"
                                label="Importancia en la gestión"
                                v-model="tab.percent"
                                suffix="%"
                                min="1"
                                max="100"
                                step="1"
                                hint="Este valor representa el porcentaje de importancia de esta métrica respecto a las demás, en un mismo intérvalo de tiempo"
                                persistent-hint
                                type="number"
                              />
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mx-0 mt-4" v-if="flow === 'edit'">
                <v-col cols="12" lg="4" md="6" sm="12">
                  <v-text-field
                    :rules="[rules.required]"
                    label="Meta establecida"
                    v-model="minimun"
                    hint="Ingrese el valor númérico de la meta establecida"
                    persistent-hint
                    type="number"
                  />
                </v-col>
                <v-col cols="12" lg="4" md="6" sm="12">
                  <v-text-field
                    :rules="[rules.required]"
                    label="Importancia en la gestión"
                    v-model="percent"
                    suffix="%"
                    min="1"
                    max="100"
                    step="1"
                    hint="Este valor representa el porcentaje de importancia de esta métrica respecto a las demás, en un mismo intérvalo de tiempo"
                    persistent-hint
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" style="height: 10%">
          <div class="row">
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'create'"
              :disabled="!valid"
              color="primary"
              @click="createItem()"
            >
              Asignar
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'edit'"
              :disabled="!valid"
              color="primary"
              @click="saveItem()"
            >
              Guardar
            </v-btn>

            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="reset"
            >
              Limpiar
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="metricPositionModal = false"
            >
              Cancelar
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'
  const date = new Date()

  export default {
    name: 'PositionMetrics',
    components: {
      SimpleList,
      Help,
    },
    props: {
      company: { type: Object },
      typeId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        tab: 0,
        valid2: true,
        valid3: true,
        config: {},
        loading: false,
        isLoading: false,
        metricPositionModal: false,
        metricPositionUserModal: false,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        metricRange: [],
        menu: false,
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Métrica', value: 'metric.description' },
          { text: 'Periodicidad', value: 'metric.periodicityText' },
          { text: 'Desde', value: 'start_at' },
          { text: 'Hasta', value: 'end_at' },
          { text: 'Meta Establecida', value: 'minimun', align: 'right' },
          { text: 'Peso relativo', value: 'percentText', align: 'center' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        itemsColumn: [
          { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Usuario 1' },
          { divider: true, inset: true },
          { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Usuario 2' },
          { divider: true, inset: true },
          { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Usuario 3' },
        ],
        items: [],
        extraButtoms: [
          {
            text: 'Métricas del personal',
            icon: 'mdi-account-group',
            event: { active: true, name: 'showPositionProfile' },
          },
        ],
        page: 1,
        pages: 1,
        item: {},
        position: null,
        metric: null,
        metrics: [],
        positions: [],
        dataMetrics: [],
        users: [],
        selectedUsers: [],
        metricPositionUsers: [],
        usersMetric: {
          value: '',
        },
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        description: '',
        type: '',
        minimun: 1,
        percent: 1,
        invert: false,
        startAt: new Date().toISOString().split('T')[0],
        endAt: new Date(date.setMonth(date.getMonth() + 1)).toISOString().split('T')[0],
        loadingText: 'Cargando registros',
        field1: null,
        checkbox: false,
        name: '',
        metricTabs: 0,
        valid: true,
        rules: {
          requiredRange: (v) => v.length === 2 || 'Seleccione un intérvalo válido.',
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            v ? v.length <= 100 : true || 'Debe poser menos de 100 caracteres',
          maxNumber: (v) =>
            v ? v.length <= 18 : true || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.getPositions()
      this.getMetrics()
    },
    filters: {
      toPeriodicityText(periodicity) {
        if (periodicity === null) return '*'
        switch (periodicity) {
          case 0: // Diario
            return 'diariamente'
          case 1: // Semanal
            return 'semanalmente'
          case 2: // Quincenal
            return 'quincenalmente'
          case 3: // Mensual
            return 'mensualmente'
          case 4: // Trimestral
            return 'trimestralmente'
          case 5: // Semestral
            return 'Semestralmente'
          case 6: // Anual
            return 'anualmente'
          default:
            return ''
        }
      },
      toRangeText(range) {
        if (!range) return ''
        return range.join(' ~ ')
      },
    },
    computed: {
      intervalText() {
        if (!this.metric && !this.metric.periodicity) return '*'
        switch (this.metric.periodicity) {
          case 0: // Diario
            return 'un día'
          case 1: // Semanal
            return 'una semana'
          case 2: // Quincenal
            return 'una quincena'
          case 3: // Mensual
            return 'un mes'
          case 4: // Trimestral
            return 'un trimestre'
          case 5: // Semestral
            return 'un semestre'
          case 6: // Anual
            return 'un año'
          default:
            return ''
        }
      },
      expPanel() {
        if (this.metricTabs && typeof this.metricTabs === 'number') {
          return [...Array(this.metricTabs).keys()].map((k, i) => i)
        } else {
          return []
        }
      },
    },
    methods: {
      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      toLocaleDateString: toLocaleDateString,
      cleanFilters() {
        this.filters = {
          search: '',
        }
      },
      handlePeriodicity() {
        this.menu = false
        const startAt = new Date(this.metricRange[0])
        const endAt = new Date(this.metricRange[1])
        const selectedDays = Math.abs((endAt - startAt) / (1000 * 3600 * 24)) + 1
        this.dataMetrics = []
        switch (this.metric.periodicity) {
          case 0: // Diario
            this.calculateIntervals(startAt, selectedDays, 0)
            break
          case 1: // Semanal
            this.calculateIntervals(startAt, selectedDays, 7)
            break
          case 2: // Quincenal
            this.calculateIntervals(startAt, selectedDays, 15)
            break
          case 3: // Mensual
            this.calculateIntervals(startAt, selectedDays, 30)
            break
          case 4: // Trimestral
            this.calculateIntervals(startAt, selectedDays, 90)
            break
          case 5: // Semestral
            this.calculateIntervals(startAt, selectedDays, 180)
            break
          case 6: // Anual
            this.calculateIntervals(startAt, selectedDays, 365)
            break
          default:
            break
        }
      },

      calculateIntervals(startAt, selectedDays, intervalDays) {
        this.metricTabs = parseInt(selectedDays / intervalDays)
        let start = new Date(
          startAt.getFullYear(),
          startAt.getMonth(),
          startAt.getDate() + 1
        )
        let end = new Date(start).setDate(new Date(start).getDate() + intervalDays)

        for (let index = 1; index <= this.metricTabs; index++) {
          this.dataMetrics.push({
            id: index,
            title: `Desde ${toLocaleDateString(
              start,
              'es-VE'
            )}, hasta ${toLocaleDateString(end, 'es-VE')}`,
            metric_id: this.metric.id,
            position_id: this.position.id,
            minimun: 1,
            percent: 100,
            menu: false,
            range: [
              new Date(start).toISOString().split('T')[0],
              new Date(end).toISOString().split('T')[0],
            ],
          })
          start = new Date(end).setDate(new Date(end).getDate() + 1)
          end = new Date(start).setDate(new Date(start).getDate() + intervalDays)
        }
      },

      assignUsersMetrics(metricPosition) {
        this.item = metricPosition
        this.getUsers()
        this.selectedUsers = null
        this.usersMetric.value = ''
        this.metricPositionUserModal = true
      },

      async getPositionMetrics() {
        if (!this.position && !this.position.name) {
          this.$swal({
            title: 'Espera',
            text:
              'Para asignar métricas, por favor selecciona un cargo del listado disponible',
            icon: 'info',
          })
          return false
        }
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          queryParams = searchString
        }
        this.$axios
          .get(
            'metric-positions?position_id=' +
              this.position.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((metricPosition) => {
              switch (metricPosition.metric.periodicity) {
                case 0:
                  metricPosition.metric.periodicityText = 'Diaria'
                  break
                case 1:
                  metricPosition.metric.periodicityText = 'Semanal'
                  break
                case 2:
                  metricPosition.metric.periodicityText = 'Quincenal'
                  break
                case 3:
                  metricPosition.metric.periodicityText = 'Mensual'
                  break
                case 4:
                  metricPosition.metric.periodicityText = 'Trimestral'
                  break
                case 5:
                  metricPosition.metric.periodicityText = 'Semestral '
                  break
                case 6:
                  metricPosition.metric.periodicityText = 'Anual'
                  break
                default:
                  metricPosition.metric.periodicityText = ''
                  break
              }
              metricPosition.percentText = metricPosition.percent + ' %'
            })
            this.loading = false
          })
      },

      async getPositions(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'positions?order=name&by=asc&limit=1000&type_id=' + this.typeId + searchStr
          )
          .then((response) => {
            this.positions = response.data
          })
      },

      async getMetrics(search) {
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get('metrics?order=description&by=asc&limit=1000' + searchStr)
          .then((response) => {
            this.metrics = response.data
          })
      },

      async getUsers(search) {
        let searchStr = ''
        let positionStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        if (this.position.id) {
          positionStr = '&position_id=' + this.position.id
        }
        this.$axios
          .get('users?with[]=avatar&limit=200' + positionStr + searchStr)
          .then((response) => {
            this.users = response.data
            this.users.sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0
            )
            this.metricPositionUsers = []
            this.users.forEach((user) => {
              this.metricPositionUsers.push({
                user: user,
                user_id: user.id,
                metric_position_id: this.item.id,
                value: '',
                description: '',
              })
            })
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.description = ''
        this.periodicity = ''
        this.metricTabs = 0
        this.dataMetrics = []

        this.metricPositionModal = true
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getPositionMetrics()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.minimun = this.item.minimun
        this.percent = this.item.percent
        this.item.menu = false
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.metricPositionModal = true
      },

      resetUsersAssignation() {
        this.$refs.generalAssignationForm.reset()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async userAssignation() {
        this.$axios
          .post('metric-position-users', {
            user_id: this.selectedUsers.id,
            metric_position_id: this.item.id,
            value: this.usersMetric.value,
            description: 'n/a',
          })
          .then(() => {
            this.metricPositionUserModal = false
            this.$swal({
              title: 'Listo',
              text: 'Las métricas fueron asignadas satisfactoriamente',
              icon: 'success',
              timer: 2000,
            })
            this.page = 1
            this.getPositionMetrics()
          })
      },

      massiveAssignment() {
        this.$axios
          .post('metric-position-user-mass-create', {
            metricPositionUser: this.metricPositionUsers,
          })
          .then(() => {
            this.page = 1
            this.getPositionMetrics()
            this.metricPositionUserModal = false
          })
      },

      async createItem() {
        this.$axios
          .post('metric-position-mass-create', { metricPosition: this.dataMetrics })
          .then(() => {
            this.page = 1
            this.getPositionMetrics()
            this.metricPositionModal = false
          })
      },

      async saveItem() {
        this.$axios
          .patch('metric-positions/' + this.item.id, {
            position_id: this.position.id,
            metric_id: this.item.metric_id,
            percent: this.percent,
            minimun: this.minimun,
            start_at: this.item.start_at,
            end_at: this.item.end_at,
          })
          .then(() => {
            this.page = 1
            this.getPositionMetrics()
            this.metricPositionModal = false
          })
      },

      deleteDataMetric(tab) {
        this.dataMetrics = this.dataMetrics.filter((metric) => metric.id !== tab.id)
      },

      async deleteItem(item) {
        this.$axios.delete('metric-positions/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getPositionMetrics()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getPositionMetrics()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-date-picker-title__date {
    font-size: inherit;
  }
  .v-dialog {
    margin: 0px;
    background-color: #ffffff;
  }
</style>
