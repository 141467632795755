<template>
  <!-- Content -->
  <v-card>
    <div class="filters">
      <v-row align="center" class="mb-1 mx-0 text-center flex-column">
        <help :helpId="37" class="help--crud" />
        <h2 class="text-center mx-5">Métricas</h2></v-row
      >
      <v-row no-gutters justify="end">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click.prevent="handleCreate()"
              >Crear
            </v-btn>
          </template>
          <span>Crear Métrica</span>
        </v-tooltip>
      </v-row>
      <label>Filtrar por:</label>
      <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
        <v-row class="mx-0" style="max-width: calc(100% - 90px)">
          <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
            <v-text-field
              dense
              solo
              hide-details="auto"
              label="Búsqueda"
              v-model="filters.search"
            />
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
            <multiselect
              track-by="id"
              label="name"
              deselect-label=""
              select-label=""
              placeholder="Periodicidad"
              v-model="filters.periodicity"
              :options="itemsPeriodicity"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="false"
            />
          </v-col>
        </v-row>
        <v-btn
          small
          color="primary"
          style="min-width: auto; position: absolute; right: 50px; height: 25px"
          dark
          class="px-1 py-1"
          v-if="filterActivated"
          @click.prevent="
            filterActivated = false
            cleanFilters()
            getMetrics()
          "
        >
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              style="min-width: auto; position: absolute; right: 10px; height: 25px"
              dark
              class="px-1 py-1"
              @click.prevent="
                filterActivated = true
                getMetrics()
              "
              ><v-icon dark> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filtrar</span>
        </v-tooltip>
      </v-form>
    </div>

    <simple-list
      :title="''"
      :dense="true"
      :headers="headers"
      :items="items"
      :totalItems="totalItems"
      :itemsPerPage="itemsPerPage"
      :loadingText="loadingText"
      :itemKey="'id'"
      :hideFooter="true"
      :hideHeader="false"
      :multiSort="false"
      :loading="loading"
      :class="'my-2 pb-2'"
      :sortable="false"
      :page="page"
      :pages="pages"
      :extraButtoms="false"
      :showCreate="false"
      :showDelete="true"
      :buttonLink="'empresas/ficha'"
      :orderOptions="orderOptions"
      @create:item="handleCreate()"
      @edit:item="handleEdit($event)"
      @delete:item="deleteItem($event)"
      @update:page="pagination($event)"
      @orderEvent="handleOrder($event)"
    />
    <modal name="metricModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Métrica</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Métrica</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Descripción"
                v-model="description"
              />
            </v-col>
            <!-- <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                v-model="type"
                :items="itemsPeriodicity"
                item-text="name"
                item-value="id"
                label="Tipo"
                single-line
              />
            </v-col> -->
            <v-col cols="12" md="6" sm="12">
              <v-select
                :rules="[rules.requiredMetric]"
                v-model="periodicity"
                :items="itemsPeriodicity"
                item-text="name"
                item-value="id"
                label="Periodicidad"
                single-line
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                :label="!invert ? 'Valor ascendente' : 'Valor descendente'"
                :hint="`Se espera que el valor logrado por el usuario sea ${
                  invert ? 'menor' : 'mayor'
                } o igual que el valor requerido`"
                :persistent-hint="true"
                v-model="invert"
              />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('metricModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </v-card>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { toLocaleDateString } from '@/utils/helpers'
  import Help from '@/components/App/Help'

  export default {
    name: 'Metrics',
    components: {
      SimpleList,
      Help,
    },
    props: {
      company: { type: Object },
      typeId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        config: {},
        metrics: [],
        loading: true,
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Descripción', value: 'description' },
          // { text: 'Tipo', value: 'type' },
          { text: 'Periodicidad', value: 'periodicityText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ],
        items: [],
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Perfiles de Cargos',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/Positions',
            href: '/administrative/human-talent/positions',
          },
          {
            text: 'Métricas',
            disabled: true,
          },
        ],
        metricsSearched: [{ id: 0, name: 'No posee empresa principal' }],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          periodicity: null,
        },
        itemsPeriodicity: [
          { id: 0, name: 'Diario' },
          { id: 1, name: 'Semanal' },
          { id: 2, name: 'Quincenal' },
          { id: 3, name: 'Mensual' },
          { id: 4, name: 'Trimestral' },
          { id: 5, name: 'Semestral' },
          { id: 6, name: 'Anual' },
        ],
        description: '',
        type: '',
        periodicity: '',
        invert: false,
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          requiredMetric: (v) => v !== '' || 'Requerido.',
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.getMetrics()
    },

    methods: {
      toLocaleDateString: toLocaleDateString,

      cleanFilters() {
        this.filters = {
          search: '',
          periodicity: null,
        }
      },

      async getMetrics() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let periodicityString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.periodicity && this.filters.periodicity.id) {
            periodicityString = '&periodicity=' + this.filters.periodicity.id
          }
          queryParams = searchString + periodicityString
        }
        this.$axios
          .get('metrics?page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((metric) => {
              switch (metric.periodicity) {
                case 0:
                  metric.periodicityText = 'Diario'
                  break
                case 1:
                  metric.periodicityText = 'Semanal'
                  break
                case 2:
                  metric.periodicityText = 'Quincenal'
                  break
                case 3:
                  metric.periodicityText = 'Mensual'
                  break
                case 4:
                  metric.periodicityText = 'Trimestral'
                  break
                case 5:
                  metric.periodicityText = 'Semestral '
                  break
                case 6:
                  metric.periodicityText = 'Anual'
                  break

                default:
                  metric.periodicityText = ''
                  break
              }
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.description = ''
        this.invert = false
        this.periodicity = ''

        this.$modal.show('metricModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getMetrics()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.description = this.item.description
        this.invert = this.item.invert === 1
        this.periodicity = this.item.periodicity
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('metricModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.$axios
          .post('metrics', {
            type_id: this.typeId,
            description: this.description,
            type: 0,
            invert: this.invert ? 1 : 0,
            periodicity: this.periodicity,
          })
          .then(() => {
            this.page = 1
            this.getMetrics()
            this.$modal.hide('metricModal')
            this.loading = false
          })
      },

      async saveItem() {
        this.$axios
          .patch('metrics/' + this.item.id, {
            type_id: this.typeId,
            description: this.description,
            type: 0,
            invert: this.invert ? 1 : 0,
            periodicity: this.periodicity,
          })
          .then(() => {
            this.page = 1
            this.getMetrics()
            this.$modal.hide('metricModal')
            this.loading = false
          })
      },

      async deleteItem(item) {
        this.$axios.delete('metrics/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getMetrics()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getMetrics()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
