<template>
  <div class="vuse-content-wrapper metrics-manager" data-app>
    <v-container fluid>
      <smart-breadcrumbs :items="navigationElements" />
      <v-row align="center" class="my-2" v-if="showTypeSelector" no-gutters
        ><span class="caption mr-1">Formato</span
        ><multiselect
          track-by="id"
          label="name"
          placeholder="Seleccionar formato"
          deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
          v-model="selectedType"
          :allow-empty="false"
          :options="types"
          :loading="loadingTypes"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="false"
          class=""
          style="max-width: 300px"
          @select="updateSelectedType($event)"
      /></v-row>

      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <template v-slot:default>
            <v-tabs v-model="model" centered slider-color="teal">
              <v-tab href="#tab-1"> Métricas </v-tab>
              <v-tab href="#tab-2"> Asignación de Métricas </v-tab>
              <!-- <v-tab href="#tab-3"> Resultados de gestión </v-tab> -->
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="model">
          <v-tab-item value="tab-1">
            <metrics
              v-if="typeId > 0"
              :key="typeIndex"
              :company="company"
              :typeId="typeId"
            />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <position-metrics
              v-if="typeId > 0"
              :key="typeIndex"
              :company="company"
              :typeId="typeId"
            />
          </v-tab-item>
          <!-- <v-tab-item value="tab-3">
            <position-metrics />
          </v-tab-item> -->
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import Metrics from '@/components/HumanTalent/Metrics'
  import PositionMetrics from '@/components/HumanTalent/PositionMetrics'

  export default {
    components: {
      Metrics,
      PositionMetrics,
    },
    data: () => ({
      model: 'tab-2',
      typeIndex: 0,
      loadingTypes: false,
      typeId: 0,
      types: [],
      selectedType: null,
      navigationElements: [
        {
          text: 'Talento Humano',
          disabled: false,
          query: null,
          params: null,
          name: 'administrative/human-talent/AdminPanel',
          href: '/administrative/human-talent/admin-panel',
        },
        {
          text: 'Gestor de Métricas',
          disabled: true,
          href: '',
        },
      ],
    }),
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {
          this.typeId = newValue.type_id
          this.typeIndex++
        },
      },
      companies() {
        if (
          this.$session.get('companies') &&
          decryptData(this.$session.get('companies'))
        ) {
          return decryptData(this.$session.get('companies'))
        } else {
          return []
        }
      },
      showTypeSelector() {
        if (this.companies.length > 0) {
          return true
        } else {
          return false
        }
      },
    },
    created() {
      this.getTypes()
      this.handleTab()
    },
    methods: {
      handleTab() {
        const route = window.location.href.split('tab=')
        if (route.length > 1) {
          this.model = route[1]
        }
      },
      getTypes() {
        this.loadingTypes = true
        this.$axios.get('types?limit=20').then((response) => {
          this.types = this.types.concat(response.data)
          this.selectedType = response.data.find((x) => x.id === this.company.type_id)
          this.typeId = this.selectedType.id
          this.loadingTypes = false
        })
      },
      updateSelectedType(event) {
        this.typeId = event.id
        this.typeIndex++
      },
    },
  }
</script>
<style lang="scss">
  .metrics-manager {
    .v-breadcrumbs {
      padding: 5px 10px !important;
    }
    .v-tabs-slider {
      margin-top: -5px;
    }
  }
</style>
